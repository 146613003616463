<template>
  <Carousel v-bind="settings" :breakpoints="breakpoints">
    <Slide v-for="(slide, index) in coopData" :key="index">
      <div class="carousel__item">
        <img :src="slide.url" />
        <div class="item-description light-color">
          <h1>{{ slide.description }}</h1>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script setup>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
const coopData = [
  {
    url: new URL("@/assets/coop1.png", import.meta.url).href,
    description: "项目开发",
  },
  {
    url: new URL("@/assets/coop2.png", import.meta.url).href,
    description: "学术研究",
  },
  {
    url: new URL("@/assets/coop3.png", import.meta.url).href,
    description: "技术支持",
  },
];
const settings = {
  itemsToShow: 1,
  snapAlign: "center",
};
// breakpoints are mobile first
// any settings not specified will fallback to the carousel settings
const breakpoints = {
  // 700px and up
  700: {
    itemsToShow: 3,
    snapAlign: "center",
  },
};
</script>
<style scoped>
.carousel__item {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.light-color {
  color: white;
  justify-content: flex-end;
}
</style>
