<template>
  <Carousel v-bind="settings" :breakpoints="breakpoints">
    <Slide v-for="(slide, index) in researchCarousel" :key="index">
      <div class="carousel__item">
        <img :src="slide.url" />
        <h3>{{ slide.description }}</h3>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script setup>
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

const researchCarousel = [
  {
    url: new URL("@/assets/research1.png", import.meta.url).href,
    description: "医学图像处理",
  },
  {
    url: new URL("@/assets/research2.png", import.meta.url).href,
    description: "医用大语言模型开发",
  },
  {
    url: new URL("@/assets/research3.png", import.meta.url).href,
    description: "大模型提示工具",
  },
];
const settings = {
  itemsToShow: 1,
  snapAlign: "center",
};
// breakpoints are mobile first
// any settings not specified will fallback to the carousel settings
const breakpoints = {
  // 700px and up
  700: {
    itemsToShow: 3,
    snapAlign: "center",
  },
};
</script>
<style scoped>
.carousel__item {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  img {
    height: 100%;
    object-fit: cover;
  }
}
</style>
