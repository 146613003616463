<template>
  <div class="footer-container row-to-col">
    <div class="section-container">
      <h3>我们的研究</h3>
      <a class="pointer">总览</a>
    </div>
    <div class="section-container">
      <h3>MedWV</h3>
      <a class="pointer">面向所有人</a>
      <a class="pointer">面向公司</a>
      <a class="pointer">登录</a>
    </div>
    <div class="section-container">
      <h3>Company</h3>
      <a class="pointer">关于我们</a>
      <a class="pointer">新闻</a>
      <a class="pointer">成员</a>
      <a class="pointer">合作伙伴</a>
      <a class="pointer">职业生涯</a>
    </div>
    <div class="section-container">
      <h3>条款和政策</h3>
      <a class="pointer">使用条款</a>
      <a class="pointer">隐私政策</a>
      <a class="pointer">其他政策</a>
    </div>
  </div>
</template>
<script setup></script>
<style scoped>
.footer-container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: whitesmoke;
  border-top: 1px solid lightgrey;
  padding: 10px;
}

.section-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding: 10px;
}
</style>
