<template>
  <Carousel v-bind="settings" :breakpoints="breakpoints">
    <Slide v-for="(slide, index) in productsData" :key="index">
      <div class="carousel__item">
        <img :src="slide.url" />
        <h4>{{ slide.description }}</h4>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script setup>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
const productsData = [
  {
    url: new URL("@/assets/products1.png", import.meta.url).href,
    description: "医用大语言模型",
  },
  {
    url: new URL("@/assets/products2.png", import.meta.url).href,
    description: "报告诊断推理",
  },
  {
    url: new URL("@/assets/products3.png", import.meta.url).href,
    description: "大语言模型辅助患者医生沟通",
  },
  {
    url: new URL("@/assets/products4.png", import.meta.url).href,
    description: "可解释医学报告生成",
  },
];
const settings = {
  itemsToShow: 1,
  snapAlign: "center",
};
// breakpoints are mobile first
// any settings not specified will fallback to the carousel settings
const breakpoints = {
  // 700px and up
  768: {
    itemsToShow: 3,
    snapAlign: "left",
  },
};
</script>
<style scoped lang="scss">
.carousel__item {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
