<template>
  <div class="header-wrapper">
    <div class="logo-box pointer" @click="goTo('/')">
      <img src="@/assets/logo_full.jpg" />
    </div>
    <div class="header-item-box">
      <div
        :class="cptNavItemActive(item.path)"
        v-for="(item, index) in paths"
        :key="index"
        @click="goTo(item.path)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="expand-outer-box">
      <Expand class="expand-outer" :expand="expand" @expand="doExpand"></Expand>
      <div
        @touchmove.stop="headerSmMove"
        @scroll.stop="headerSmMove"
        @touchend.stop="headerSmMove"
        class="expand-header-box"
        :style="{ transform: expand ? 'translateY(0)' : 'translateY(-100%)' }"
      >
        <div
          v-for="(item, index) in paths"
          :key="index"
          :class="cptNavItemActive(item.path)"
          @click="goTo(item.path)"
          :style="removeBorder(index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Expand from "@/components/Expand.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
const paths = [
  {
    name: "首页",
    path: "/",
  },
  {
    name: "关于我们",
    path: "/aboutUs",
  },

  {
    name: "研究",
    path: "/research",
  },
  {
    name: "产品",
    path: "/products",
  },
  {
    name: "合作",
    path: "/cooperate",
  },
  {
    name: "login",
    path: "/login",
  },
];
const expand = ref(false);

const removeBorder = computed(() => (index) => {
  if (index === paths.length - 1) {
    return `border: none`;
  }
  return "border-bottom: 2px solid lightgrey";
});

const router = useRouter();

const headerSmMove = (e) => {
  console.log("moving");
  console.log(e);
};

const cptNavItemActive = computed(() => {
  return function (path) {
    const router = useRouter();
    if (path === router.currentRoute.value.path) {
      return "items item-word pointer item-active";
    }
    return "items item-word pointer";
  };
});
const doExpand = (val) => {
  expand.value = val;
};

const goTo = (view) => {
  router.push({
    path: view,
  });
  expand.value = false;
};
</script>

<style lang="scss" scoped>
.logo-box {
  width: 15%;
  min-width: 150px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: 98%;
    object-fit: contain;
  }
}
.header-wrapper {
  width: 100vw;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: 1px solid lightgrey;
  z-index: 999;

  .header-item-box {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .items {
    width: 80px;
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: black;
  }

  .item-word {
    transition: all 0.15s ease-in-out;
  }

  .item-word:hover {
    font-weight: bolder;
  }

  .item-active {
    color: cornflowerblue;
    font-weight: bolder;
  }

  .expand-outer-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    .expand-outer {
      right: 10px;
      position: absolute;
      z-index: 1;
    }

    .expand-header-box {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba($color: white, $alpha: 0.95);
      transform: translateY(-100%);
      transition: transform 0.35s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.header-login {
  position: absolute;
  left: 85%;
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.header-login:hover {
  font-weight: bolder;
}

// .header-fade-enter-active,
// .header-fade-leave-active {
//   transition: opacity 0.35s ease-out;
// }

// .header-fade-enter,
// .header-fade-leave-to {
//   opacity: 0;
// }

/* 进入之前和离开后的样式 */
.header-fade-enter-from,
.header-fade-leave-to {
  opacity: 0;
}
/* 离开和进入过程中的样式 */
.header-fade-enter-active,
.header-fade-leave-active {
  /* 添加过渡动画 */
  transition: opacity 0.5s ease;
}
/* 进入之后和离开之前的样式 */
.header-fade-enter-to,
.header-fade-leave-from {
  opacity: 1;
}
</style>
