export const news = [
  {
    url: new URL("@/assets/news1.png", import.meta.url).href,
    title:
      "66家企业角逐！“中国创翼”创业创新大赛大连市选拔赛收官，世象医疗科技（大连）有限公司荣获“二等奖”",
    detail:
      "2024年7月2日第六届“中国创翼”创业创新大赛大连市选拔赛在大连人力资源服务产业园收官。本届选拔赛由大连市人社局联合市教育局、市科学技术局、市农业农村局、市退役军人事务局、市人民政府国有资产监督管理委员会共同主办。大赛以“创响新时代 共圆中国梦——创赢新突破 共绘振兴篇”为主题，按照“主体赛+专项赛”模式举办，共设先进制造、现代服务、乡村振兴、银发经济和绿色经济5个赛道。大赛共吸引66家创业创新企业报名参赛，经过初赛、复赛、决赛三个阶段的激烈角逐，世象医疗科技（大连）有限公司荣获银发经济赛道“二等奖”。",
    photos: [
      new URL("@/assets/news1_1.png", import.meta.url).href,
      new URL("@/assets/news1_2.png", import.meta.url).href,
    ],
  },
  {
    url: new URL("@/assets/news2.png", import.meta.url).href,
    title: "世象医疗科技顺利入围高层次人才创业扶持“海创工程”项目",
    detail:
      "2023年6月29日至7月1日 ，第24届中国海外学子（大连）创业周在大连国际会议中心举行。本届海创周以“海纳英才 创新未来”为主题，搭建人才、项目、资本、市场对接交流平台，汇聚全球优质创新创业资源，推动创新链、产业链、资金链、人才链深度融合。本届海创周有近千名海外学子参加，200余个科技项目亮相，星海论坛、国家科技计划成果路演等30余场活动集中举行。\n\t在2023年海创周上，科技项目路演是“主角”之一，来自美国、英国、德国、加拿大、日本等16个国家和地区的海外学子将在20余场项目路演中，以世界最新科技成果助力科技创新。世象医疗科技团队本次参加教育部“春晖杯”获奖赛道项目路演，并顺利入围高层次人才创业扶持“海创工程”项目。",
    photos: [new URL("@/assets/news2_1.png", import.meta.url).href],
  },
  {
    url: new URL("@/assets/news3.png", import.meta.url).href,
    title: "世象医疗科技团队荣获第十六届“春晖杯”中国留学人员创新创业大赛优胜奖",
    detail:
      "\t作为国内第一个服务于全球中国留学人员回国创新创业的综合服务平台，在国家教育部、科技部的支持下，在“春晖杯”大赛各主办、承办、协办单位的共同努力下，海外留学人员参赛项目的数量和质量逐年提升。拳拳游子意，殷殷故国情，谁言寸草心，报得三春晖！中国梦，海归梦，创业梦。助力海外学子创新创业，点燃报国情怀激情梦想，“春晖杯”大赛为广大海外学子的回国创新创业之路保驾护航。\n\t受新冠肺炎疫情影响，2021年第十六届“春晖杯”大赛依托大赛在线服务平台于线上举行。经过线上项目路演及激烈角逐，世象医疗科技团队在“其他高新技术领域”组获得优胜奖。",
    photos: [new URL("@/assets/news3_1.png", import.meta.url).href],
  },
];
