<template>
  <Carousel
    v-bind="settings"
    :breakpoints="breakpoints"
    :wrapAround="true"
    :transition="500"
  >
    <Slide v-for="(slide, index) in news" :key="index">
      <div class="carousel__item" @click="goToNews(index)">
        <div class="news-pic">
          <img :src="slide.url" />
        </div>
        <div class="news-title">
          <span>{{ slide.title }}</span>
        </div>
      </div>
    </Slide>
    <template #addons>
      <Pagination />
      <Navigation />
    </template>
  </Carousel>
</template>

<script setup>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import { useRouter } from "vue-router";
const router = useRouter();
import "vue3-carousel/dist/carousel.css";
import { news } from "@/data/news";
const settings = {
  itemsToShow: 1,
  snapAlign: "center",
};
// breakpoints are mobile first
// any settings not specified will fallback to the carousel settings
const breakpoints = {
  // 700px and up
  768: {
    itemsToShow: 3,
    snapAlign: "center",
  },
};

const goToNews = () => {
  router.push({
    path: "/news",
  });
};
</script>

<style scoped lang="scss">
.carousel__item {
  min-height: 500px;
  width: 250px;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
.news-title {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  span {
    margin-bottom: 20px;
  }
}

.news-pic {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
