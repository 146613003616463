<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Carousel :autoplay="3000" :wrap-around="true">
    <Slide :index="0">
      <div class="carousel__item">
        <img src="@/assets/Picture1.png" />
        <div class="item-description">
          <h1>MedWV</h1>
          <h3>专注于医疗场景的大语言模型</h3>
        </div>
      </div>
    </Slide>
    <Slide :index="1">
      <div class="carousel__item">
        <h1>尝试任何与医疗相关的问题</h1>
        <input class="carousel-input" placeholder="输入您的问题" />
      </div>
    </Slide>

    <Slide :index="2">
      <div class="carousel__item">
        <img src="@/assets/Picture3.png" />
        <div class="item-description">
          <h1>大语言模型&临床记录</h1>
          <h3>基于大语言模型的临床记录诊断推理</h3>
        </div>
      </div>
    </Slide>

    <Slide :index="3">
      <div class="carousel__item">
        <img src="@/assets/Picture4.png" />
        <div class="item-description">
          <h1>技术开发委托与研究合作</h1>
        </div>
      </div>
    </Slide>

    <template #addons>
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "BannerCarousel",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
});
</script>

<style lang="scss" scoped>
.carousel__item {
  height: 500px;
  width: 100%;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    border-radius: 8px;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carousel__slide {
  padding: 10px;
}

.carousel-input {
  width: 60vw;
  max-width: 500px;
  height: 40px;
  padding: 5px;
  border-radius: 20px;
  border: 2px solid whitesmoke;
}
</style>
